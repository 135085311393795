<template>
  <div class="-skills-section">
    <section class="section">
      <div class="container">
        <SectionTitle title="Skills" />
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-8">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <LanguagesTile />
              </div>
              <div class="tile is-parent">
                <FrameworksTile />
              </div>
            </div>
            <div class="tile is-parent">
              <ToolsTile />
            </div>
          </div>
          <div class="tile is-parent">
            <ConceptsTile />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SectionTitle from "@/components/ui/SectionTitle.vue";
import LanguagesTile from "@/components/pages/home/skills-section/LanguagesTile.vue";
import FrameworksTile from "@/components/pages/home/skills-section/FrameworksTile.vue";
import ToolsTile from "@/components/pages/home/skills-section/ToolsTile.vue";
import ConceptsTile from "@/components/pages/home/skills-section/ConceptsTile.vue";

export default {
  name: "SkillsSection",
  components: {
    SectionTitle,
    LanguagesTile,
    FrameworksTile,
    ToolsTile,
    ConceptsTile
  }
};
</script>

<style scoped lang="scss"></style>
