<template>
  <div class="-app-jumbotron">
    <section class="-side-spacer">
      <div class="container">
        <div class="columns is-mobile">
          <div
            class="column"
            v-for="guide in guides"
            v-bind:key="guide.id"
            :class="guide.hideClass"
          >
            <div
              class="-column"
              v-anime="{ opacity: ['0%', '100%'], duration: 5000, delay: 200 }"
            ></div>
          </div>
        </div>
        <section class="-vertical-align">
          <div class="hero-body">
            <div class="container">
              <figure class="image is-128x128 -image-margin-adjust">
                <img class="is-rounded" src="@/assets/img/headshot/01.jpg" />
              </figure>
              <br />
              <h1
                class="title has-text-centered is-size-1-fullhd is-size-2-desktop is-size-3-touch is-family-secondary -font-stuff"
              >
                Hi. I'm John 👋
              </h1>
              <div class="-terminal-window">
                <div class="-terminal-heading has-text-centered">
                  <span>🏠 johnbauer@johnbauer.me</span>
                </div>
                <div class="-terminal-body">
                  <span class="is-family-code -has-text-green"
                    >~% I'm a designer and developer that loves bringing
                    software projects to life.
                    <span class="-cursor" ref="blinkingCursor">&#9608;</span>
                  </span>
                </div>
              </div>
              <div class="-call-to-action has-text-centered">
                <p class="subtitle is-size-6">
                  Interested in working together?
                  <router-link to="/contact">Let's Chat.</router-link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import blinkAnimationMixin from "@/mixins/animations/blinkAnimationMixin.js";

export default {
  name: "HomePageIndex",
  data() {
    return {
      guides: [
        {
          id: 1,
          hideClass: ""
        },
        {
          id: 2,
          hideClass: ""
        },
        {
          id: 3,
          hideClass: ""
        },
        {
          id: 4,
          hideClass: ""
        },
        {
          id: 5,
          hideClass: "is-hidden-mobile"
        },
        {
          id: 6,
          hideClass: "is-hidden-mobile"
        },
        {
          id: 7,
          hideClass: "is-hidden-mobile"
        },
        {
          id: 8,
          hideClass: "is-hidden-mobile"
        },
        {
          id: 9,
          hideClass: "is-hidden-touch"
        },
        {
          id: 10,
          hideClass: "is-hidden-touch"
        },
        {
          id: 11,
          hideClass: "is-hidden-touch"
        },
        {
          id: 12,
          hideClass: "is-hidden-touch"
        }
      ]
    };
  },
  mixins: [blinkAnimationMixin],
  mounted() {
    this.animateBlink(this.$refs.blinkingCursor);
  }
};
</script>

<style scoped lang="scss">
.-image-margin-adjust {
  margin: 0 auto;
}

.-side-spacer {
  padding-right: 32px;
  padding-left: 32px;
}

@media only screen and (max-width: 1024px) {
  .-side-spacer {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.-column {
  background-color: #fff3f3;
  height: 600px;
  width: 100%;
}

.-has-text-green {
  color: #00ff00;
}

.-vertical-align {
  margin-top: -560px;
  margin-bottom: 50px;
}

.-terminal-heading {
  background-color: #f5f5f5;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
  border-top-left-radius: 10px;
  border-top-right-radius: 6px;
  border: 1px solid grey;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica";
}

.-terminal-body {
  padding: 10px;
  font-size: 0.95em;
}

.-terminal-window {
  background-color: #212121;
  margin: 0 auto;
  min-height: 130px;
  max-height: 240px;
  max-width: 600px;
  border-radius: 10px;
}

.-call-to-action {
  margin-top: 16px;
}

.-font-stuff {
  font-family: baskerville-urw, serif;
}
</style>
