<template>
  <article class="tile is-child notification">
    <div class="content">
      <p class="title is-size-4 is-size-5-mobile">Tools</p>
      <div class="level is-mobile" v-for="tool in tools" :key="tool.name">
        <div class="level-left">
          <div class="level-item">
            <img class="-tool-image has-margin-right-10" :src="tool.image" />
            <span class="-tool-text">
              {{ tool.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "FrameworksTile",
  data() {
    return {
      tools: [
        {
          text: "Sketch",
          image: require("@/assets/img/logos/tools/01.svg")
        },
        {
          text: "InVision",
          image: require("@/assets/img/logos/tools/02.svg")
        },
        {
          text: "Figma",
          image: require("@/assets/img/logos/tools/03.svg")
        },
        {
          text: "Adobe XD",
          image: require("@/assets/img/logos/tools/04.svg")
        },
        {
          text: "Adobe Photoshop",
          image: require("@/assets/img/logos/tools/05.svg")
        },
        {
          text: "Adobe Illustrator",
          image: require("@/assets/img/logos/tools/06.svg")
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.-tool-image {
  height: 24px;
  width: 24px;
}
</style>
