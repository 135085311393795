<template>
  <div class="-what-i-do-section">
    <section class="section">
      <div class="container">
        <SectionTitle title="What I Do." />
        <hr />
        <p class="subtitle is-size-5">
          Creating great digital products involves a wide range of disciplines.
          As someone who's used to working on small cross-functional teams, I
          love being a generalist that can get things done.
        </p>
        <div class="columns">
          <div class="column" v-for="skill in skills" :key="skill.title">
            <div class="card -card">
              <div class="card-content">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img :src="skill.image" alt="Placeholder image" />
                  </figure>
                </div>
                <div class="content has-text-centered">
                  <p
                    class="title is-spaced has-text-weight-bold is-size-4 is-size-5-mobile"
                  >
                    {{ skill.title }}
                  </p>
                  <p class="subtitle is-size-6">
                    {{ skill.summary }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SectionTitle from "@/components/ui/SectionTitle.vue";

export default {
  name: "WhatIDoSection",
  data() {
    return {
      skills: [
        {
          title: "Design",
          summary:
            "I started off in UX/UI Design, and I've loved it ever since. Whether it's early-stage research and wireframing or high-fidelity prototypes and handoffs, I've got it covered.",
          image: require(`@/assets/img/illustrations/skills/01.png`)
        },
        {
          title: "Development",
          summary:
            "Having a well-rounded understanding of software is important to me. I specialize in frontend development, but I'm no stranger to the world of servers, databases, and ops. ",
          image: require(`@/assets/img/illustrations/skills/02.png`)
        },
        {
          title: "Product",
          summary:
            "Implementation means nothing without a solid product concept. With a background in marketing and product management, I recognize the importance of high-level planning.",
          image: require(`@/assets/img/illustrations/skills/03.png`)
        }
      ]
    };
  },
  components: {
    SectionTitle
  }
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1440px) {
  .-card {
    min-height: 500px;
  }
}

@media only screen and (max-width: 1024px) {
  .-card {
    min-height: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .-card {
    min-height: auto;
  }
}
</style>
