<template>
  <div class="-app-navbar" id="nav">
    <template>
      <b-navbar :fixed-top="true" class="-navbar-border">
        <template slot="brand">
          <b-navbar-item
            class="has-text-black"
            tag="router-link"
            :to="{ path: '/' }"
          >
            <b>John Bauer</b>
          </b-navbar-item>
        </template>
        <template slot="end">
          <b-navbar-item
            class="-page"
            tag="router-link"
            :to="{ path: '/work' }"
          >
            Work
          </b-navbar-item>
          <b-navbar-item
            class="-page"
            tag="router-link"
            :to="{ path: '/contact' }"
          >
            Contact
          </b-navbar-item>
        </template>
      </b-navbar>
    </template>
  </div>
</template>

<script>
export default {
  name: "AppNavbar"
};
</script>

<style scoped lang="scss"></style>
