<template>
  <div class="-ui-page-title">
    <h3 class="title is-size-3-desktop is-size-4-touch has-margin-bottom-10">
      <span class="has-text-info has-margin-right-10">#</span>{{ title }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: String
  }
};
</script>

<style scoped lang="scss"></style>
