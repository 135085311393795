<template>
  <article class="-frameworks-tile tile is-child notification">
    <div class="content">
      <p class="title is-size-4 is-size-5-mobile">Frameworks</p>
      <div
        class="level is-mobile"
        v-for="framework in frameworks"
        :key="framework.name"
      >
        <div class="level-left">
          <div class="level-item">
            <img
              class="-framework-image has-margin-right-10"
              :src="framework.image"
            />
            <span class="-framework-text">
              {{ framework.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "FrameworksTile",
  data() {
    return {
      frameworks: [
        {
          text: "Vue.js",
          image: require("@/assets/img/logos/frameworks/01.svg")
        },
        {
          text: "React.js",
          image: require("@/assets/img/logos/frameworks/02.svg")
        },
        {
          text: "Express.js",
          image: require("@/assets/img/logos/frameworks/03.svg")
        },
        {
          text: "PostgreSQL",
          image: require("@/assets/img/logos/frameworks/04.svg")
        },
        {
          text: "MongoDB",
          image: require("@/assets/img/logos/frameworks/05.svg")
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.-framework-image {
  height: 24px;
  width: 24px;
}
</style>
