<template>
  <div class="home">
    <HomePageIndex />
  </div>
</template>

<script>
import HomePageIndex from "@/components/pages/home/PageIndex.vue";

export default {
  name: "home",
  title: "John Bauer | Home",
  components: {
    HomePageIndex
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style scoped lang="scss"></style>
