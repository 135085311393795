/* For blinking text */

export default {
  methods: {
    animateBlink(value) {
      this.$anime({
        targets: value,
        opacity: [1, 0],
        duration: 400,
        delay: 800,
        loop: true
      });
    }
  }
};
