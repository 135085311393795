<template>
  <article class="-languages-tile tile is-child notification">
    <div class="content">
      <p class="title is-size-4 is-size-5-mobile">Languages</p>
      <div
        class="level is-mobile"
        v-for="language in languages"
        :key="language.name"
      >
        <div class="level-left">
          <div class="level-item">
            <img
              class="-language-image has-margin-right-10"
              :src="language.image"
            />
            <span class="-language-text">
              {{ language.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "LanguagesTile",
  data() {
    return {
      languages: [
        {
          text: "JavaScript ES6",
          image: require("@/assets/img/logos/languages/01.svg")
        },
        {
          text: "Python",
          image: require("@/assets/img/logos/languages/02.svg")
        },
        {
          text: "SQL",
          image: require("@/assets/img/logos/languages/03.svg")
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.-language-image {
  height: 24px;
  width: 24px;
}
</style>
