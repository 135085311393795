<template>
  <div id="app" class="-layout-main-wrapper">
    <div class="-layout-main-container">
      <AppNavbar />
      <transition
        mode="out-in"
        name="router-anim"
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
      >
        <router-view />
      </transition>
    </div>
    <AppFooter class="-layout-footer" />
  </div>
</template>

<script>
import AppNavbar from "@/components/layout/AppNavbar.vue";
import AppFooter from "@/components/layout/AppFooter.vue";

export default {
  name: "app",
  components: {
    AppNavbar,
    AppFooter
  }
};
</script>

<style lang="scss"></style>
