<template>
  <article class="-concepts-tile tile is-child notification">
    <div class="content">
      <p class="title is-size-4 is-size-5-mobile">Concepts</p>
      <div
        class="level is-mobile"
        v-for="concept in concepts"
        :key="concept.name"
      >
        <div class="level-left">
          <div class="level-item">
            <b-icon
              icon="check"
              type="is-success"
              class="-concept-image has-margin-right-10"
              :src="concept.image"
            />
            <span class="-concept-text">
              {{ concept.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "FrameworksTile",
  data() {
    return {
      concepts: [
        {
          text: "UX Design"
        },
        {
          text: "UI Design"
        },
        {
          text: "Graphic Design"
        },
        {
          text: "Product Management"
        },
        {
          text: "Frontend Development"
        },
        {
          text: "Backend Development"
        },
        {
          text: "Business Consulting"
        },
        {
          text: "Sales & Marketing"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.-concept-image {
  height: 24px;
  width: 24px;
}
</style>
