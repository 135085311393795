<template>
  <div class="-home-page-index">
    <AppJumbotron />
    <WhatIDoSection />
    <SkillsSection />
  </div>
</template>

<script>
import AppJumbotron from "@/components/pages/home/AppJumbotron.vue";
import SkillsSection from "@/components/pages/home/skills-section/SkillsSection.vue";
import WhatIDoSection from "@/components/pages/home/WhatIDoSection.vue";

export default {
  name: "HomePageIndex",
  components: {
    AppJumbotron,
    WhatIDoSection,
    SkillsSection
  }
};
</script>

<style scoped lang="scss"></style>
